.modaloverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalcontent {
  position: relative;
  background-image: linear-gradient( 180deg, #fffffffa, #aafffffa);
  padding: 8px;
  max-width: 98%; /* 必要な高さを指定 */
  max-height: 98%; /* 必要な高さを指定 */
  overflow-y: auto; /* モーダルのスクロールを有効にする */
  border-radius: 10px;
}


.closemodal {
  position: absolute;
  top: 0px;
  right: 8px;

  background-image: linear-gradient( 90deg, #ffffffaa, transparent);
  background-color: red;
  color: white;
  font-weight: bold;
  font-size: 1rem;
}

.modalwallpaperType1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%; /* 必要な幅を指定 */
  margin: 0 auto;
}

.modalwallpaperType1 img {
  max-width: 29%;
  margin: 10px;
  transform: rotate(-0deg);
}

.modalCardGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 768px) {
  .modalCardGrid {
    grid-template-columns: 1fr; /* 3行1列に変更 */
  }
}

