@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.new-badge {
  position: absolute;
  top: 20px;
  right: -15px;
  transform: rotate(45deg);
  background-color: red;
  color: white;
  padding: 5px;
  font-weight: bold;
  animation: blink 5s infinite;
}