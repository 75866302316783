.container {
  position: relative;
  justify-content: center;
  height: 240px;
}

.element1,
.element2 {
  position: absolute;
  width: 100%;
}

.element1 {
  z-index: 1; /* element1をelement2の上に表示 */
}

.element2 {
  z-index: 2; /* element2をelement1の下に表示 */
}
