.scroll {
  margin     : auto;
  width      : 97%;
  font-size  : 15em;
  line-height: 1.5em;
  text-align : center;
/*  border     : 1px solid #666; */
  color      : #ffffffaa;
/*  background : #ffffffaa; */  
  overflow   : hidden;
}

.scroll span{
  display     : inline-block;
  padding-left: 100%;
  white-space : nowrap;
  line-height : 1em;
  animation   : scrollAnime 120s linear infinite;
}

@keyframes scrollAnime{
    0% { transform: translateX(0)}
  100% { transform: translateX(-100%)}
}

@font-face {
  font-family: 'MyCustomFont';
  src: url('../../public/font/electroharmonix.otf') format('opentype');
}

.electro {
  font-family: 'MyCustomFont';
}

.fixed {
  position  : absolute;
  top       : 10px;
  right     : 0;
  text-align : left;

  margin     : auto;
  width      : 97%;
  font-size  : 2.5em;
  line-height: 1.5em;
  color      : #ffffffdd;
  overflow   : hidden;
}

@media (max-width: 768px) {
  .fixed {
  font-size  : 2.5em;
  line-height: 1.5em;
  overflow   : hidden;
}
}
