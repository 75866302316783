.top {
  position: relative;
  max-width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient( 90deg, #ffffffaa, transparent);
  margin: 0px 0px 10px 0px; /* top right bottom left */
}

.aboutpage_title {
  position: relative;
  max-width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(140deg, #000ff088, #00000044);
  border-radius: 10px;
  padding: 10px 0px 10px 0px;
}

.aboutpage_text {
  width: 70%;
  margin: 0 auto; /* 左右の余白を自動的に均等に設定して中央に配置します */
}


.city {
  width: 100%;
  height: auto;
  max-width: 400px;
  max-height: 400px;
  border-radius: 5%;
  object-fit: cover;
}

.elementCBDC {
  color: #000000;
  /* フォントボーダー */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #aa00b6;

  font-family: "Inter-Regular", Helvetica;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.sentence {
  color: #000000;
  font-family: "Inter-Regular", Helvetica;
  font-size: 1.2em;
  margin-bottom: 20px;
  text-align: left;
  width: 100%;
  max-width: 100%;
}

.section_storyimg {
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 10px 0px 0px 0px;
  background-image: linear-gradient(140deg, #000ff044, #00000044);
  text-align: center;
  border-radius: 10px;
}

.arrowDiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrowImg {
  justify-content: center;
  align-items: center;
  width: 15%;
  height: auto;
}

.section_sentence {
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 30px 10px 30px; /* top right bottom left */
  margin: 10px 0px 0px 0px;
  background-image: linear-gradient(140deg, #ffffffbb, #ffffffbb);
  border-radius: 30px;
}

/* メディアクエリ: 画面幅が768px以下の場合 */
@media (max-width: 768px) {
  .aboutpage_text {
    width: 90%;
    margin: 0 auto; /* 左右の余白を自動的に均等に設定して中央に配置します */
  }


  .section_sentence {
    padding: 10px 10px 10px 10px; /* top right bottom left */
    border-radius: 30px;
  }
}
