// Animate the size, outside
.pulse:hover, 
.pulse:focus {
  animation: pulse 1s;
  box-shadow: 0 0 0 2em transparent;
}

.pulse:active {
  animation: pulse-active 0.1s; // Use a different animation for :active state
  box-shadow: 0 0 0 2em transparent;
}

@keyframes pulse {
  0% { box-shadow: 0 0 0 0 var(--hover); }
}

@keyframes pulse-active {
  0% { transform: scale(1); }
  100% { transform: scale(0.8); }
}

//=== Set button colors
// If you wonder why use Sass vars or CSS custom properties...
  // Make a map with the class names and matching colors
$colors: (
  pulse: #C6F5E9, 
);

// Sass variables compile to a static string; CSS variables are dynamic and inherited
  // Loop through the map and set CSS custom properties using Sass variables
@each $button, $color in $colors {
  .#{$button} {
    --color: #{$color};
    --hover: #{$color};
  }
}

// Now every button will have different colors as set above. We get to use the same structure, only changing the custom properties.
button {  
  color: #0f0fff;
  transition: 0.25s;
  
  &:hover,
  &:focus { 
    border-color: #C6F5E9;
    color: #0f0fff;
  }
}


// Basic button styles
button {
  background: linear-gradient(140deg, #FCD6EDaa, #C6F5E9aa);
  border: 2px solid;
  border-color: #0f0fff;
  font: inherit;
  line-height: 1;
  margin-top: 0.8em;
  padding: 0.6em 2em;
  border-radius: 10px;
}

