.top {
  position: relative;
  max-width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient( 90deg, #ffffffaa, transparent);
}

.sentence {
  color: #000000;
  font-family: "Inter-Regular", Helvetica;
  font-size: 0.7rem;
}


.button {
  width: 100%;
  margin-bottom: 5px;
  background-image: linear-gradient( 90deg, #ffffffaa, transparent);
}

.title {
  color: #000000;
  /* フォントボーダー */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #b60000;

  font-family: "Inter-Regular", Helvetica;
  font-size: 1.4em;
  margin-bottom: 5px;
}

