$color_white: #fff7fb;
$color_black: #010000;
$color_red: #f0f;
.neon {
  fontStyle: 'italic';
  line-height: 1.2;
  text-align: center;
  color: $color_white;
  font-family: "Vibur", sans-serif;
  animation: neon 1s infinite alternate;
  @keyframes neon {
    0% {
      text-shadow: 0 0 0.02em $color_white, 0 0 0.04em $color_white,
        0 0 0.06em $color_white, 0 0 0.1em $color_red, 0 0 0.4em $color_red,
        0 0 0.5em $color_red, 0 0 0.7em $color_red, 0 0 0.8em $color_red;
    }
    100% {
      text-shadow: 0 0 0.04em $color_white, 0 0 0.08em $color_white,
        0 0 0.1em $color_white, 0 0 0.3em $color_red, 0 0 0.5em $color_red,
        0 0 0.6em $color_red, 0 0 0.8em $color_red, 0 0 1em $color_red;
    }
  }
}