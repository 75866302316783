.root {
  position: relative;
  width: 100%; /* 幅を100%に設定 */
  height: 100vh;
  align-items: center; /* 縦方向の中央揃え */
  justify-content: center; /* 横方向の中央揃え */
  padding: 0px 20px 0px 20px;
  background-image: url('../../public/img/bg/bg1.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: auto;
  overflow-x: hidden;
}


.top {
  position: relative;
  max-width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bottom {
  margin-top: 10px;
  position: relative;
  width: 98%;

  height: 270px; /* 任意の高さに調整 */
  object-fit: cover; /* 要素のサイズに合わせて画像をカバーする */
  object-position: center; /* 画像の中央を基準にする */
}

.nftshop {
  margin-top: 5px;
  position: relative;
  width: 98%;
  height: 98%;

  object-fit: cover; /* 要素のサイズに合わせて画像をカバーする */
  object-position: center; /* 画像の中央を基準にする */
}


.app {
  width: 100%;
  margin: 0 auto;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.content {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  padding: 0px 10px 0px 10px; /* top right bottom left */
}

.content_shop {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 45% 55%;
  justify-content: start;
  align-items: start;
  padding: 0px 5px 0px 5px; /* top right bottom left */
}

.content_center {
  display: grid;
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
}

.section {
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 10px 0px 10px 0px;
  background-image: linear-gradient(140deg, #FCD6EDbb, #C6F5E9bb);
  text-align: center;
  border-radius: 10px;
}

.section_left {
  justify-content: left;
  align-items: left;
  padding: 10px;
  margin: 10px 0px 10px 0px;
  background-image: linear-gradient(140deg, #FCD6EDbb, #C6F5E9bb);
  text-align: left;
  border-radius: 10px;
}

.section_black {
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 10px 0px 0px 0px;
  background-image: linear-gradient(140deg, #000ff088, #00000088);
  text-align: center;
  border-radius: 10px;
}

.section_shopTitle_left {
  justify-content: center;
  align-items: center;
/*  background-image: linear-gradient(140deg, #000ff088, #00000088); */
  text-align: center;
  border-radius: 10px;
}

.section_shopTitle_right {
  max-width: 100%;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 0px 0px; /* top right bottom left */
  padding: 5px;
  background-image: linear-gradient(140deg, #000ff088, #00000088);
  text-align: center;
  border-radius: 10px;
}

.section_shopTitle {
  justify-content: center;
  align-items: center;
  margin: 3px 5px 0px 5px; /* top right bottom left */
  background-image: linear-gradient(140deg, #FCD6EDbb, #C6F5E9bb);
  text-align: center;
  border-radius: 10px;
}

.twitter {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  text-align: center;
  padding: 10px;
}

@media (max-width: 768px) {
  .content {
    margin: 0px 10px 0px 10px;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr;
  }

  .content_shop {
    margin: 0px 10px 0px 10px;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr;
  }
}

.warningText {
  font-size: 0.8rem;
  margin-left: 10px;
}

.sentence100 {
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 0px 10px 0px; /* top right bottom left */
  margin: 10px 0px 10px 0px;
  background-image: linear-gradient(45deg, #ffffffaa, #ffffff22);
  border-radius: 10px;
}

.sentence100_title {
  position: relative;
  max-width: 100%;
  color: white;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(140deg, #000ff088, #00000044);
  border-radius: 3px;
  padding: 10px 0px 10px 10px;
}


/* 1行3列のグリッド */
.grid_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px; /* グリッドアイテム間の間隔（調整が必要な場合は変更してください） */
  margin: 5px 0px 0px 0px; /* top right bottom left */
}

/* メディアクエリ: 画面幅が768px以下の場合 */
@media (max-width: 768px) {
  .grid_container {
    grid-template-columns: 1fr; /* 3行1列に変更 */
  }
}

/* グリッドの中身 */
.grid_item {
  background-image: linear-gradient(45deg, #ffffffdd, #ffffff55);
  padding: 20px;
  text-align: center;
  border-radius: 5px;
}

.grid_item_title {
  text-decoration: underline;
  font-weight: bold;
  font-size: 1.0rem;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
}

.grid_container_one {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2px; /* グリッドアイテム間の間隔（調整が必要な場合は変更してください） */
  margin: 5px 0px 0px 0px; /* top right bottom left */
}

.grid_item_one {
  background-image: linear-gradient(45deg, #ffffffdd, #ffffff55);
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  max-width: 100%;

}
