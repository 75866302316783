.underlinelink {
  text-decoration: none; /* 下線を除去するための設定 */
}

.underlinelink:hover {
  text-decoration: underline; /* マウスホバー時にアンダーバーを追加する設定 */
}

@font-face {
  font-family: 'MyCustomFont';
  src: url('../../public/font/electroharmonix.otf') format('opentype');
}

.electro {
  font-family: 'MyCustomFont';
}

.title {
  font-size: 2rem;
  margin-left: 10px;
}

@media (max-width: 420px) {
  .title {
    font-size: 1.5rem;
    margin-left: 5px;
    }
}


.address {
  font-family: 'Arial';
  font-size: 1rem;
}

.titlegradient {
  background: linear-gradient(to right, #ff77b0, #77c9ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}


